<template>
    <div>
        <a-button @click="handleClick">导出</a-button>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "ExportDemo",
        data() {
            return {

            };
        },
        props: {//组件属性
        },
        methods: {//方法
            handleClick() {
                var op = {
                    url: "/Test/Index",//下载地址
                    data: {},//下载接口参数
                    FileName: '用户数据.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>